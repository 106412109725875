.library-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    overflow: auto;
    scrollbar-width: thin;
    scrollbar-color: var(--secondary-color) var(--palette-color);
    padding: 0 10px 10px 0;
}

.choosing-component-container {
    height: 55px;
    display: flex;
}

.library-block-title {
    display: block;
    margin-right: auto;
    white-space: nowrap;
    align-self: center;
    font-size: 18px;
    color: var(--code-color);
}

.component-container {
    min-height: 100px;
    max-height: 500px;
    padding: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--library-component-text-color);
    border-radius: 4px;
}

.component-container img {
    max-height: 130px;
}

.imported-component * {
    color: #000000;
}
