.editor-settings-item-container {
    padding: 2px 5px;
    min-width: 70px;
    height: 27px;
    display: flex;
    flex-wrap: nowrap;
    border: 1px solid var(--primary-color);
    border-radius: 4px;
    cursor: pointer;
    user-select: none;
    font-size: var(--settings-font-size-2);
    color: var(--text-color);
    transition: var(--default-transition);
}

.editor-settings-item-container:hover {
    border: 1px solid var(--highlight-color);
    transition: var(--default-transition);
}

.editor-settings-item {
    display: flex;
    gap: 5px;
    white-space: nowrap;
    align-items: center;
}

.editor-settings-item {
    display: flex;
    flex-wrap: nowrap;
}

.editor-settings-item:hover .settings-item-icon {
    fill: var(--highlight-color);
    transition: var(--default-transition);
}

.editor-settings-item:active .settings-item-icon {
    opacity: var(--hover-button-opacity);
    transition: var(--default-transition);
}

.settings-item-icon {
    width: 19px;
    height: 16px;
    filter: var(--svg-settings-color);
    fill: var(--svg-settings-color);
    transition: var(--default-transition);

}

.theme-icon {
    width: 19px;
    height: 16px;
    filter: var(--highlight-color);
    fill: var(--highlight-color);
}