.preview-explorer {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    overflow: auto;
    scrollbar-width: thin;
    scrollbar-color: var(--secondary-color) var(--palette-color);
}

.left-panel {
    height: 100%;
}

.preview-tabs-container {
    display: flex;
    user-select: none;
}
