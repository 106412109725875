.project-template-card {
    min-width: 280px;
    height: 100px;
    padding: 30px 53px 5px;
    border: 1px solid var(--outline-grey-color);
    border-radius: 2px;
}

.project-template-card:hover {
    cursor: pointer;
    border-color: var(--highlight-color);
    outline: 1px solid var(--highlight-color);
}

.project-template-card-visual-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    margin-bottom: 5px;
}

.project-template-card-icon {
    flex-shrink: 0;
    max-height: 40px;
}

.template-data-type-icon {
    width: 40px;
    height: 40px;
}

.template-arrow-icon {
    width: 48px;
    height: 24px;
}

.project-template-card-title {
    /* font-size: 12px; */
    font-weight: 300;
    text-align: center;
}