:root {
    --editor-settings-toolbars-height: 36px;
    --editor-settings-toolbars-margin: 15px;
}

.code-editor-toolbars {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    margin-bottom: var(--editor-settings-toolbars-margin);
    padding: 0 5px 0 35px;
    border-bottom: 1px solid var(--secondary-color);
    box-shadow: var(--workspace-shadow);
}