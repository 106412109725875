.displayed-files-selector-container {
    position: relative;
    flex-shrink: 0;
}

.files-selector-toggle-button {
    width: 42px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--box-color);
    border: 1px solid var(--secondary-color);
    border-top: 3px solid var(--secondary-color);
    transition: var(--default-transition);
    cursor: pointer;
}

.files-selector-toggle-button:hover {
    background-color: var(--primary-color);
}

.files-selector-toggle-button.active {
    background-color: var(--primary-color);
    border-top-color: var(--highlight-color);
}

.files-selector-toggle-button-icon {
    width: 24px;
    height: 20px;
}

.files-selector-container {
    top: 110%;
    z-index: 3;
    position: absolute;
    max-height: 0;
    overflow: hidden;
    transition: var(--default-transition) all;
    background-color: var(--primary-color);
    border-radius: 4px;
    box-shadow: var(--tooltip-shadow);
    visibility: hidden;
    padding: 8px;
}

.files-selector-container.open {
    visibility: visible;
    border: 1px solid var(--highlight-color);
    max-height: 250px;
    display: flex;
    flex-direction: column;
}

.files-selector-content {
    flex: auto;
    overflow: auto;
    padding-right: 10px;
}

h3 {
    pointer-events: none;
    font-size: 18px;
    font-weight: 300;
    margin-bottom: 8px;
}

.file-visibility-selector {
    margin-bottom: 8px;
    min-width: 100px;
    max-width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}