main {
    padding-top: var(--navbar-height);
    height: 100%;
}

.workspace {
    height: 100%;
    width: 100%;
    padding: 30px 30px 5px 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
    background-color: var(--box-container-color);
    transition: var(--default-transition);
}
