.custom-loader-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
}

.custom-loader-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.custom-loader-icon-container {
    width: 135px;
    height: 100px;
}

.custom-loader-text-container {
    font-size: 28px;
    color: var(--outlined-text-color);
}

.custom-container-svg-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
}

.border {
    position: absolute;
}

.border {
    transform-origin: 50% 50%;
    animation: rotate 2s linear infinite;
}

@keyframes rotate {
    to {
        transform: rotate(360deg);
    }
}