.outlined-button {
    max-width: 140px;
    height: var(--button-height);
    padding: 7px 9px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--button-text-color);
    background-color: transparent;
    border: 1px solid var(--button-text-color);
    border-radius: var(--buttons-border-radius-1);
    font-size: var(--buttons-font-size-1);
    font-weight: 600;
    letter-spacing: var(--buttons-letter-spacing-1);
    cursor: pointer;
    white-space: nowrap;
    transition: var(--default-transition);
}

.outlined-button:hover {
    border-color: var(--highlight-color);
    color: var(--highlight-color);
    transition: var(--default-transition);
}

.outlined-button:active {
    opacity: 0.8;
    transition: var(--default-transition);
}
