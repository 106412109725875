.labeled-checkbox {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
}

.labeled-checkbox:hover {
    cursor: pointer;
}

.labeled-checkbox:hover .checkbox-item {
    border-color: var(--highlight-color);
}

.labeled-checkbox.active .checkbox-item {
    border-color: var(--highlight-color);
    background-color: var(--highlight-color);
}

.checkbox-item {
    width: 18px;
    height: 18px;
    border: 1px solid var(--outline-grey-color);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    margin-right: 10px;
    flex-shrink: 0;
}

.checkbox-label {
    min-width: 50px;
    font-size: 16px;
}