.outlined-textarea-container {
    position: relative;
    width: 100%;
    display: inline-flex;
    font-size: 16px;
    flex-direction: column;
}

.textarea {
    width: 100%;
    min-height: 45px;
    padding: 14px;
    display: flex;
    justify-content: space-between;
    border-radius: 4px;
    border: 1px solid var(--outline-grey-color);
    background-color: var(--primary-color);
    transition: var(--default-transition);
}

.text-container {
    color: var(--code-color);
    font-weight: 400;
    white-space: pre-wrap;
    display: flex;
    flex-direction: column;
    gap: 1px;
    overflow: auto;
}

.text-container pre {
    scrollbar-width: thin;
    scrollbar-color: var(--secondary-color) var(--palette-color);
}

.live-code-subtitle {
    margin: 10px 0 10px 0;
    font-weight: 600;
    color: var(--outlined-text-color);
}

.instructions-block span {
    line-height: 18px;
}

.outlined-area-bold-text {
    text-decoration: underline;
    color: var(--outlined-text-color);
}

.text-container::selection {
    background-color: var(--highlight-color);
    color: white;
}

.copy-button-container {
    width: 1%;
    display: flex;
    justify-content: flex-end;
}

.copy-text-icon {
    width: 15px;
    height: 17px;
    fill: var(--outlined-text-color);
    cursor: pointer;
}

.copy-text-icon:hover {
    fill: var(--highlight-color);
}

.textarea-label {
    position: absolute;
    top: -13px;
    left: 25px;
    padding: 0 6px;
    white-space: nowrap;
    font-weight: 500;
    z-index: 1;
    user-select: none;
    color: var(--outlined-text-color);
    background-color: var(--primary-color);
    transition: var(--default-transition);
}

.textarea-label,
.text-container {
    letter-spacing: 0.5px;
}

.tooltip {
    height: 20px;
    position: relative;
    right: -20px;
    top: -10px;
    padding: 0.3em 0.6em;
    pointer-events: none;
    transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
    z-index: -1;
    border-radius: 4px;
    scale: 0;
    font-weight: 600;
    background: var(--box-color);
    box-shadow: var(--box-color) 0 8px 15px;
    user-select: none;
}

.tooltip.copied {
    top: -40px;
    right: 0px;
    opacity: 0.8;
    visibility: visible;
    pointer-events: auto;
    scale: 1;
    z-index: 1;
}