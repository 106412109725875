.stub-container {
    height: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
    user-select: none;
}

.stub-icon, .stub-info {
    max-width: 245px;
}

.stub-icon {
    width: 115px;
    height: 263px;
}

.singularity-stub-icon {
    width: 100%;
    height: 100%;
}

.stub-info {
    display: flex;
    flex-direction: column;
    gap: 15px;
    color: var(--text-color);
}

.stub-title {
    color: var(--text-color);
    font-size: 20px;
    font-weight: 500;
    text-align: center;
}

.stub-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    font-weight: 300;
    font-size: 18px;
}

.stub-body-line {
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
}

.stub-body-line .stub-span {
    height: 27px;
    display: block;
    line-height: 23px;
    white-space: nowrap;
}

.stub-body-line .compile-button {
    border: 1px solid var(--compile-button-color);
    border-radius: 50px;
}