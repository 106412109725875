.system-alert {
    position: relative;
    z-index: var(--modal-layout-z-index-2);
    animation: move 0.1s linear;
}

@keyframes move {
    from {
        left: -200px;
    }
    to {
        left: 0;
    }
}
