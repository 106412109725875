.file-tab-menu-buttons {
    flex-shrink: 0;
    height: 100%;
    width: 37px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    filter: var(--svg-default-color);
    fill: var(--text-color);
}

.file-tab-body .menu-icon{
    width: 100%;
    width: 15px;
    height: 15px;
}

.icon-tab-container {
    cursor: pointer;
    filter: var(--svg-default-color);
}
