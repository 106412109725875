.tab {
    height: 35px;
    padding: 0 20px;
    transition: var(--default-transition);
    background-color: var(--box-color);
    border: 1px solid var(--secondary-color);
    border-top: 3px solid var(--secondary-color);
    border-bottom: none;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
}

.tab:hover {
    cursor: pointer;
    background-color: var(--primary-color);
}

.tab.active {
    z-index: 5;
    position: relative;
    border-top-color: var(--highlight-color);
    background-color: var(--primary-color);
}