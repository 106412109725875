.compile-button {
    width: 116px;
    height: 26px;
    display: flex;
    justify-content: center;
    column-gap: 5px;
    flex-wrap: nowrap;
    align-items: center;
    border: 1px solid transparent;
    border-radius: 5px;
}

.compile-button:hover {
    cursor: pointer;
    border: 1px solid var(--highlight-color, blue);
}

.compile-button.disabled {
    pointer-events: none;
    opacity: 0.4;
}

.compile-button .compile-icon, .compile-button .stop-compile-icon {
    width: 15px;
    height: 16px;
    fill: var(--compile-button-color);
    transition: var(--default-transition);
}

.compile-icon {
    fill: var(--compile-button-color-hover);
    transition: var(--default-transition);
}

.compile-button-label {
    display: block;
    font-weight: 500;
    font-size: 16px;
    color: var(--compile-button-color);
    transition: var(--default-transition);
}

.stop-compile-icon {
    fill: var(--error-border-color);
    transition: var(--default-transition);
}

.cancel-button-label {
    color: var(--error-border-color);
    transition: var(--default-transition);
}


.compile-button:hover .compile-icon,
.compile-button:hover .compile-button,
.compile-button:hover .compile-button-label {
    fill: var(--compile-button-color-hover);
    color: var(--compile-button-color-hover);
    transition: var(--default-transition);
}

