.devtools-control-panel {
    display: flex;
    flex-wrap: nowrap;
    background-color: var(--palette-color)
}

.devtools-panel-content {
    box-shadow: var(--workspace-shadow);
    box-sizing: border-box;
    overflow: auto;
    padding: 30px 20px;
    height: 100%;
    width: 100%;
}