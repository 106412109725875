.error-block {
    display: flex;
    padding: 7px 11px;
    align-items: center;
    border-style: solid;
    border-width: 1px;
    border-radius: 4px;
    border-color: var(--error-border-color);
    background-color: var(--error-background-color);
    color: var(--error-border-color);
}