.tooltip-wrapper {
    position: relative;
}

.tooltip-text {
    top: 110%;
    opacity: 0;
    display: none;
    position: absolute;
    border-radius: 4px;
    color: var(--text-color);
    transition: opacity var(--default-transition);
    background-color: var(--primary-color);
    border: 1px solid var(--highlight-color);
    box-shadow: var(--tooltip-shadow);
    max-width: 0;
    padding: 5px 12px;
}

.tooltip-wrapper:hover .tooltip-text {
    z-index: 101;
    min-width: 150px;
    max-width: 200px;
    opacity: 1;
    display: block;
}

.tooltip-wrapper:hover.expanded .tooltip-text {
    max-width: 100%;
    width: 100%;
}
