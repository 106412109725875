.split-bar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15px;
    flex-shrink: 0;
    background-color: var(--highlight-color);
    cursor: col-resize;
    transition: var(--default-transition);
    touch-action: none;
    z-index: 50;
}

.split-line {
    width: 8px;
    height: 20px;
    stroke: var(--palette-color);
}
