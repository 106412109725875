header {
    min-width: var(--main-content-min-width);
    height: var(--navbar-height);
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-color: var(--navbar-color);
}

.header-content {
    max-width: var(--main-content-max-width);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    transition: var(--default-transition);
    padding: 0px 30px;
    margin: auto;
}

.nav-side {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    gap: 20px;
}

.left-nav-side {
    max-width: 467px;
}

.sign-out-button {
    padding: 0 !important;
}

.sign-out-button svg{
    color: var(--primary-color);
}

.sign-out-button:hover svg{
    color: var(--highlight-color);
}