.no-results-panel {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: auto;
}

.no-results-info-block {
    text-align: center;
    font-size: 26px;
    color: var(--outlined-text-color);
}

.not-found-icon {
    width: 130px;
    height: 130px;
}