.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: var(--modal-layout-z-index);
}

.default-wrapper {
    backdrop-filter: blur(4px);
}

.locker-wrapper {
    overflow: hidden;
    backdrop-filter: blur(4px);
    background-color: rgba(0, 0, 0, 0.3);
}
