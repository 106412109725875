.main-menu {
    padding: 25px;
    background-color: var(--box-container-color);
    border-radius: 8px;
    position: relative;
}

.main-menu-buttons {
    width: 100%;
    display: flex;
    gap: 25px;
}