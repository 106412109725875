:root {
    --file-explorer-height: 35px;
}

.file-explorer-toolbar {
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.file-explorer-toolbar-button {
    cursor: pointer;
    height: var(--file-explorer-height);
    width: var(--file-explorer-height);
    background-color: var(--box-color);
    border: 1px solid var(--secondary-color);
    border-top: 3px solid var(--secondary-color);
    transition: var(--default-transition);
}

.file-explorer-toolbar-button:hover {
    background-color: var(--primary-color);
    transition: var(--default-transition);
}

.add-icon {
    width: 17px;
    height: 17px;
    filter: var(--svg-default-color);
    fill: var(--svg-default-color);
}

.upload-icon {
    width: 15px;
    height: 15px;
    filter: var(--svg-default-color);
    fill: var(--svg-default-color);
}