.search-bar {
    height: 48px;
    margin-bottom: 14px;
}

.search-bar input {
    width: 100%;
    height: 100%;
    outline: none;
    font-size: 16px;
    border-radius: 2px;
    padding: 0 45px 0 15px;
    color: var(--outlined-text-color);
    border: 1px solid var(--outline-grey-color);
    background-position: calc(100% - 15px) 15px;
    background-image: url("/public/assets/images/searchIcon.svg");
    background-repeat: no-repeat;
}

.search-bar input:focus {
    border-color: var(--highlight-color);
}