.file-tab {
    height: 100%;
    flex-shrink: 0;
    padding: 0 10px;
    min-width: 100px;
    user-select: none;
    cursor: pointer;
    transition: var(--default-transition) all;
}

.file-tab:focus-within {
    border: 1px solid var(--highlight-color);
}

.file-tab.renaming {
    max-width: 1000px;
    border: 1px solid var(--highlight-color);
    user-select: auto;
}

.file-tab-body {
    flex-shrink: 0;
    height: 100%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid transparent;
}

.file-tab-name {
    flex-shrink: 0;
    margin-right: 16px;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
