.editor-container {
    height: calc(100% - var(--file-explorer-height) - var(--editor-settings-toolbars-height) - var(--editor-settings-toolbars-margin));
    width: 100%;
    background-color: var(--palette-color);
    transition: var(--default-transition);
    display: flex;
    align-items: center;
    justify-content: center;
}

.editor-stub-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.editor-stub-icon {
    width: 115px;
    height: 263px;
}

.monaco-editor,
.minimap-slider,
.margin,
.minimap,
.minimap-shadow-visible,
.monaco-editor-background,
.minimap-slider-horizontal,
.minimap .slider-mouseover {
    transition: background-color var(--default-transition);
}