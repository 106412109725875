.project-templates-list {
    /* display: flex; */
    /* flex-wrap: wrap; */
    /* column-gap: 35px; */
    /* row-gap: 25px; */
    display: grid;
    grid-row-gap: 25px;
    grid-column-gap: 35px;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    padding-bottom: 105px;
    padding-right: 10px;
    overflow: auto;
}
