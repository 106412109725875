.divider {
    width: 100%;
    display: flex;
    align-items: center;
}

.long-hyphen-icon {
    width: 31px;
    height: 10px;
}

.div-divider {
    width: 100%;
    margin: 24px auto;
    border-bottom: 1px solid var(--outline-grey-color);
}
