.component-select {
    margin-left: auto;
    max-width: 412px;
    user-select: none;
    position: relative;
}

@media (max-width:1260px) {
    .component-select {
        position: relative;
        margin-left: 100px;
    }
}

.component-select-wrapper {
    width: 100%;
    height: 49px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 14px;
    cursor: pointer;
    border: 1px solid var(--outline-grey-color);
    border-radius: 4px;
    transition: var(--default-transition);
}

.component-select-wrapper:hover div {
    opacity: 0.7;
    transition: var(--default-transition);
}

.component-select-text,
.component-option-text {
    color: var(--text-color);
    font-size: 16px;
    white-space: nowrap;
}

.component-select-arrow {
    width: 12px;
    height: 8px;
    display: flex;
}

.component-select-options-container {
    position: relative;
    width: 100%;
    height: 10px;
    visibility: hidden;
    opacity: 0;
    z-index: var(--selector-z-index);
    background-color: var(--primary-color);
    cursor: pointer;
}

.component-select-options-container.shown {
    height: 200px;
    visibility: visible;
    opacity: 1;
    border: 1px solid var(--outline-grey-color);
    border-top: none;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    overflow-y: scroll;
    scrollbar-width: thin;
}

.component-select-option {
    position: relative;
    width: 100%;
    margin-left: auto;
    padding: 10px 15px;
    display: block;
    text-align: end;
    border-top: 1px solid var(--theme-border-option-color);
}

.component-select-options-container
.component-select-option-container:first-of-type {
    width: 100%;
    transition: all var(--default-transition);
}

.component-select-option-container {
    display: flex;
}

.component-select-option-container:hover {
    background-color: var(--hover-option-color);
    transition: all var(--default-transition);
}

.selected-option {
    font-weight: 600;   
    color: var(--highlight-color);
}

.component-select-label {
    width: 200px;
    color: var(--button-text-color);
}

.main-logo {
    margin: auto;
    justify-self: center;
}

