:root {
    /* colors */
    --navbar-color: #220D3A;
    --highlight-color: #0088ff;
    --primary-color: #ffffff;
    --secondary-color: #cfcfcf;
    --palette-color:  #c8c8c8;
    --box-color: #7f7f7f;
    --text-color:#000000;
    --bad-highlight: #ff0000;
    --warn-highlight: #ffff00;
    --good-highlight: #00ff00;
    --info-highlight: #0000ff;

    --error-border-color: #E67381;
    --error-background-color: #FDE5E8;
    --box-container-color: #F6F6F6;
    --outline-grey-color: #D6D6D6;
    --outlined-text-color: #666666;
    --code-color: #353535;
    --compile-button-color: #00C48C;
    --compile-button-color-hover: #019e71;
    

    --library-component-text-color: #F6F6F6;
    
    --svg-default-color: #000000;
    --svg-settings-color: #CFCFCF;
    
    --button-background: #4086ff;
    --button-hover-background: #005ACB;
    --button-text-color:#ffffff;
    --button-text-color-hover:#cfcfcf;

    --border-custom-select-color: rgba(246, 246, 246, 0.3);
    --hover-option-color: rgba(217, 217, 217, 0.1);

    --workspace-shadow: 0px -2px 3px 0px rgba(0, 0, 0, 0.10);
    --main-menu-button-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.141);
    --tooltip-shadow: 0px 0px 5px rgba(0, 0, 0, 0.141);

    /* sizes */
    --buttons-letter-spacing-1: 1.25px;
    --buttons-font-size-1: 12px;
    --buttons-border-radius-1: 4px;
    --button-height: 30px;

    --selector-z-index: 50;
    --modal-layout-z-index: 100;
    --modal-layout-z-index-2: 120;
    --modal-border-radius: 8px;

    /* misc */
    --default-transition: 0.3s;

    --hover-button-opacity: 0.7;
    --highlight-color-filter: brightness(0) saturate(100%) invert(36%) sepia(39%) saturate(3510%) hue-rotate(191deg) brightness(100%) contrast(107%);
}

.light {
    --navbar-color: #220D3A;
    --primary-color: #ffffff;
    --secondary-color: #cfcfcf;
    --palette-color:  #F6F6F6;
    --box-color: #EFEFEF;
    --text-color:#000000;
    --bad-highlight: #ff0000;
    --warn-highlight: #fff1e4;
    --good-highlight: #aaffa2;
    --info-highlight: #e4e6f1;

    --box-container-color: #F6F6F6;
    --outlined-input-color: #ffffff;
    --code-color: #353535;
    --library-component-text-color: #F6F6F6;

    --main-menu-button-shadow: 0px 0px 3px 0px #cfcfcf;

    --svg-default-color: invert(0%) sepia(2%) saturate(13%) hue-rotate(324deg) brightness(103%) contrast(100%);
}

.dark {
    --navbar-color: #1f1f1f;
    --primary-color: #1e1e1e;
    --secondary-color: #3a3a3a;
    --palette-color: #1f1f1f;
    --box-color: #292b2c;
    --text-color:#ffffff;
    --bad-highlight: #dd4c35; 
    --warn-highlight: #dcb67a;
    --good-highlight: #aaffa2;
    --info-highlight: #1572b6;

    --box-container-color: #222222;
    --outlined-input-color: #292b2c;
    --outlined-text-color: #c9c9c9;
    --code-color: #c9c9c9;
    --library-component-text-color: #d6d6d6;

    --main-menu-button-shadow: 0px 0px 3px 0px rgba(207, 207, 207, 0.141);

    --svg-default-color: invert(100%) sepia(0%) saturate(0%) hue-rotate(96deg) brightness(102%) contrast(102%);
}

.contrast {
    --navbar-color: #000000;
    --primary-color: #000000;
    --secondary-color: #1a1a1a;
    --palette-color:  #000000;
    --box-color: #2d2d2d;
    --text-color:#ffffff;
    --bad-highlight: #ff0000 
    --warn-highlight: #ffff00;
    --good-highlight: #00ff00;
    --info-highlight: #0000ff;

    --box-container-color: #101010;
    --outlined-input-color: #2d2d2d;
    --outlined-text-color: #c9c9c9;
    --code-color: #c9c9c9;
    --library-component-text-color: #F6F6F6;

    --main-menu-button-shadow: 0px 0px 3px 0px rgba(207, 207, 207, 0.141);

    --svg-default-color: invert(100%) sepia(0%) saturate(0%) hue-rotate(96deg) brightness(102%) contrast(102%);
}