.main-menu-button {
    padding: 16px;
    width: 139px;
    height: 145px;
    cursor: pointer;
    padding: 10px 0px;
    border-bottom: 4px solid var(--palette-color);
    box-shadow: var(--main-menu-button-shadow);
    border-radius: 4px;
}

.main-menu-button:hover {
    border-color: var(--button-background);
}

.main-menu-button-svg {
    display: block;
    width: 78px;
    height: 87px;
    margin: auto;
    fill: var(--palette-color);
    margin-bottom: 8px;
}

.main-menu-button-label {
    text-align: center;
    white-space: nowrap;
    user-select: none;
}
