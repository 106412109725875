
.file-tab-body form {
    display: flex;
    flex-wrap: nowrap;
    box-sizing: border-box;
    height: 100%;
}

.file-tab-body input {
    flex-shrink: 0;
    font-family: inherit;
    font-size: inherit;
    height: 100%;
    border: none;
    outline: none;
    background-color: inherit;
}

.file-tab-body button {
    flex-shrink: 0;
    display: block;
    height: 100%;
    padding: 0 5px;
    background: transparent;
    border: none;
    background-color: var(--box-color);
    border-left: 1px solid var(--secondary-color);
    border-right: 1px solid var(--secondary-color);
    transition: var(--default-transition);
}

.file-tab-body button:hover {
    cursor: pointer;
    background-color: var(--palette-color);
    transition: var(--default-transition);
}