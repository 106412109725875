.close-button-container {
    position: absolute;
    right: 0;
    top: 0;
    padding: 5px;
    cursor: pointer;
    filter: var(--svg-default-color);
    width: 25px;
    height: 25px;
}

.close-button {
    width: 100%;
    height: 100%;
    transition: var(--default-transition);
}

.close-button:hover {
    transform: scale(1.1);
}
