.signup-main-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.signup-info h2{
  margin: 0;
  font-size: 32;
  letter-spacing: -0.5;
  line-height: "48px";
  font-weight: 600;
}

.signup-content {
  width: 71%;
  margin: 0 auto;
}

.signup-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  box-sizing: border-box;
}

.additional-info span {
  word-break: break-all;
}