.workspace-panels {
    width: 100%;
    height: 100%;
    display: flex;
    overflow: hidden;
    align-items: stretch;
    background-color: var(--primary-color);
    transition: var(--default-transition);
    min-width: calc(var(--main-content-min-width) - 60px);
    scroll-behavior: smooth;
}

.workspace-panel {
    flex-shrink: 0;
}

.tablet-mode .workspace-panel {
    width: 100%;
}

.workspace-panel.code-editor {
    display: flex;
    flex-direction: column;
}

.workspace-panel.developer-tools {
    position: relative;
    /* z-index: 3; */
    margin-left: auto;
    padding-bottom: 35px;
    background-color: var(--primary-color);
}