.screen-switch-container {
    padding: 0 15px;
    flex-shrink: 0;
    margin-left: auto;
    align-self: center;
}

.screen-switch-button {
    width: 27px;
    height: 27px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background-color: var(--highlight-color);
    border: 1px solid var(--highlight-color);
}

.screen-switch-button.inverted {
    transform: rotate(180deg);
}

.screen-switch-button:hover {
    background-color: var(--primary-color);
}

.screen-switch-button:hover use {
    filter: var(--highlight-color-filter);
}

.tablet-arrow {
    width: 17px;
    height: 13px;
}