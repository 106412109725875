.file-explorer {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    transition: background-color var(--default-transition);
    background-color: var(--box-container-color);
    color: var(--text-color);
}

.files-tabs-list-wrapper {
    display: flex;
    overflow-x: auto;
}

.file-explorer .tab {
    padding: 0;
}