.authorization-form {
    width: 500px;
    padding: 20px;
    min-height: 200px;
    padding: 25px;
    background-color: var(--box-container-color);
    border-radius: 8px;
}

.authorization-form .form, 
.authorization-form .form form{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.authorization-form  .additional-info {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.links-section {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
}

.links-section .link-to-form {
    color: var(--highlight-color);
    font-weight: 600;
    cursor: pointer;
}

.errors-container {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    font-size: 14px;
}

.authorization-form .buttons-container {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 20px
  }
  
.authorization-form .buttons-container button {
    min-width: 140px;
}

.dump-for-focus-fields {
    margin-right: 0 !important;
}

.authorization-form input {
    padding-left: 14px  !important;
}

.authorization-form .MuiInputBase-root {
    padding-left: 0px  !important;
}

#outlined-username-input-helper-text {
    word-break: break-all;
}